import { SanityLocale } from "../../localisation";
import groq from "groq";

const _defaultLocale = SanityLocale.en_GB;

interface CreateSanityFieldLocaliserConfig {
  defaultLocale: string;
}

export const createSanityFieldLocalisers = ({
  defaultLocale,
}: CreateSanityFieldLocaliserConfig) => {
  // default locale should be in snake case

  const localisedArrayField = (fieldName: string, fallback: any[] = []) => {
    return groq`coalesce(${fieldName}[$locale], ${fieldName}["${defaultLocale}"], "${fallback}")`;
  };

  const localisedField = (fieldName: string, fallbackText: string = "Missing translation") => {
    return groq`coalesce(${fieldName}[$locale], ${fieldName}["${defaultLocale}"], "${fallbackText}")`;
  };

  return { localisedArrayField, localisedField };
};

// default locale should be in snake case

const { localisedArrayField, localisedField } = createSanityFieldLocalisers({
  defaultLocale: _defaultLocale,
});

export { localisedField, localisedArrayField };
