import groq from "groq";
import internalLinkFields from "./internalLinkFields";

export const standardLinkFields = groq`
    _key,   
    name,
    "internalLink": internalLink -> {
        "newWindow": ^.newWindow,
        ${internalLinkFields}

    },
    "externalLink": {
        "link": externalUrl,
        "newWindow": newWindow
    },
`;

export default standardLinkFields;
