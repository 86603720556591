import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { customBlockContentFields } from "../customBlockContentFields";
import { productAttributeOptionsFields } from "./productAttributeOptionFields";
import { productAttributeValueGroupingFields } from "./productAttributeValueGroupingFields";

export const productAttributeFields = groq`
    "sanityId": _id,
    "id": shortId,
    "title": ${localisedField("title")},
    "description": ${localisedField("description", "")},
    "richTextDescription": ${localisedField("richTextDescription", "")}[] {
      ${customBlockContentFields}
    },
    rfqMin->{
      ${productAttributeOptionsFields}
    },
    rfqMax->{
      ${productAttributeOptionsFields}
    },
    allowCustomOption,
    displayType,
    type,
    printArea,
    dynamicImagery,
    requiredDimensions,
    options[]-> {
      ${productAttributeOptionsFields}
    },
    valueGroupings[] {
      ${productAttributeValueGroupingFields}
    }
`;
