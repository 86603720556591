import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { imageWithCaptionFields } from "../widgets/imageWithCaptionFields";
import { productFeatureFields } from "./productFeatureFields";

export const technicalContentSpecItemFields = groq`
    "title": ${localisedField("title")},
    "_key": _key,
    image {
        ${imageWithCaptionFields}
    },
    "colour": colour,
    productFeatures[]-> {
        ${productFeatureFields}
    }
`;
