import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { imageFields } from "../widgets/imageFields";
import { imageWithCaptionFields } from "../widgets/imageWithCaptionFields";

export const configuratorModalFields = groq`
  _id,
  "title": ${localisedField("title")},
  "subtitle": ${localisedField("subtitle", "")},
  contentBlocks[] {
    _key,
    "title": ${localisedField("title", "")},
    "subtitle": ${localisedField("subtitle", "")},
    "content": ${localisedField("content")},
    image {
      ${imageFields}
    }
  }
`;
export const configuratorModalInstanceFields = groq`
  _key,
  modal-> {
    ${configuratorModalFields}
  },
  "triggerLabel": ${localisedField("triggerLabel")},
  textAlign,
  imagePosition
`;
export const contextualInformationFields = groq`
    _key,
    title,
    type,
    "text": ${localisedField("text")},
    modals[] {
      ${configuratorModalInstanceFields}
    },
    isDismissable,
    ${configuratorModalInstanceFields},
`;

export const productConfigSectionFields = groq`
  "id": _key,
  "title": ${localisedField("title")},
  "description": ${localisedField("description", "")},
  "reference": reference.current,
  iconName,
  contextualInformation[] {
    ${contextualInformationFields}
  },
  attributes[] {
    "attributeId": attribute->shortId,
    isTitleHidden,
    hideAfter,
    "tooltipMessage": ${localisedField("tooltipMessage", "")},
    "customTabLabel": ${localisedField("customTabLabel", "")},
  },
  useImageGallery,
  imageGallery[] {
    ${imageWithCaptionFields}
  }
`;
