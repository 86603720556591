import groq from "groq";
import standardLinkFields from "../layout/standardLinkFields";
import { imageWithCaptionFields } from "./imageWithCaptionFields";

export const imageLinkFields = groq`
    _type,
    image {
        ${imageWithCaptionFields}
    },
    link {
        ${standardLinkFields}
    },
`;
