import groq from "groq";
import { localisedField } from "../groq-utils/localisedField";
import { imageFields } from "./widgets/imageFields";

export const pageMetaFields = groq`
    "metaTitle": ${localisedField("metaTitle", "")},
    "metaDescription": ${localisedField("metaDescription", "")},
    openGraphImage {
        ${imageFields}
    },
    disableCrawl
`;
