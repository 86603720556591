import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { imageFields } from "../widgets/imageFields";
import { learnMoreModalFields } from "./learnMoreModalFields";

export const productFeatureFields = groq`
    "id": _id,
    "title": ${localisedField("title")},
    "description": ${localisedField("description", "")},
    image {
        ${imageFields}
    },
    "imageFallbackText": ${localisedField("imageFallbackText", "")},
    learnMoreModal {
        ${learnMoreModalFields}
    }
`;
