import {
  Image,
  ProductAttribute,
  ProductAttributeValue,
  TransportPriority,
} from "@sourceful/shared-types";
import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { imageFields } from "../widgets/imageFields";
import { productAttributeFields } from "./productAttributeFields";
import { productAttributeOptionsFields } from "./productAttributeOptionFields";
import { customBlockContentFields } from "../customBlockContentFields";

export interface SourcefulPresetQuery {
  id: string;
  _id: string;
  baseProductId: number;
  versionId: number;
  name: string;
  isDefault?: boolean;
  description: any; // Portable Text
  fixedAttributes: string[];
  attributes: { attribute: ProductAttribute; value: ProductAttributeValue[] }[];
  images: Image[];
  slug: string;
  fromPrice?: string;
  productSlug?: string;
  shortDescription?: string;
  defaultTransportPriority?: TransportPriority;
}

export const SanityPresetFields = groq`
    _id,
    "id": _id,
    baseProductId,
    versionId,
    status,
    isDefault,
    "name": ${localisedField("name")},
    "description": ${localisedField("description", "")}[] {
        ${customBlockContentFields}
    },
    attributes[] {
        attribute-> {
            ${productAttributeFields}
        },
        value[]-> {
            ${productAttributeOptionsFields}
        }
    },
    "fixedAttributes": fixedAttributes[]->_id,
    images[] {
        ${imageFields}
    },
    "isSourcefulPreset": true,
    "slug": ${localisedField("slug")}.current,

    "productSlug": ${localisedField("productMain->slug")}.current,
    "shortDescription": ${localisedField("shortDescription", "")},
    "fromPrice": ${localisedField("fromPrice", "")},
    defaultTransportPriority,
`;
