// This script updates all documents referencing a given product version with the latest version
import { SanityClient } from "@sanity/client";
import {
  fetchSanityDocsReferencingPreset,
  fetchSanityDocsReferencingProduct,
  fetchSanityPresetsForProduct,
  GenericSanityDocument,
} from "../fetch-utils";
import { fetchSanityProductById } from "../fetchSanityProductById";
import { replaceDocumentReferences } from "../replaceDocumentReferences";
import { Patch } from "../createPatch";
import { SourcefulPresetQuery } from "@src/sanity-utils/fragments";

const createPatch = (updatedDoc: any, _rev: string) => ({
  id: updatedDoc._id,
  patch: {
    set: updatedDoc,
    ifRevisionID: _rev,
  },
});

export const NO_REFERENCE_WARNING = "No docs referencing this product or its presets found";

const docToPatch = (doc: any) => createPatch(doc, doc?._rev);
export interface Args {
  oldProductId: string;
  newProductId: string;
  client: SanityClient;
  onError?: (error: any) => void;
  onNoReferencesFound?: () => void;
  autoCommit?: boolean;
}

export interface UpdateProductReferencesReturn {
  patchesForProductReferences: Patch[];
  patchesForPresetReferences: Patch[];
  docsWithReferencesToProduct: GenericSanityDocument[];
  docsWithReferencesToPresets: GenericSanityDocument[];
  warnings?: string[];
}

export const updateProductReferences = async ({
  oldProductId,
  newProductId,
  client,
  onError,
  onNoReferencesFound,
  autoCommit = true,
}: Args): Promise<UpdateProductReferencesReturn> => {
  try {
    const { projectId, dataset, apiVersion } = client.config();
    console.log("Sanity Config", { projectId, dataset, apiVersion });

    // 1. Look for docs directly referencing the product _id
    const docsWithReferences = await fetchSanityDocsReferencingProduct(client, oldProductId);

    const newProduct = await fetchSanityProductById(client, newProductId);

    if (!newProduct) {
      throw new Error(`Could not find product with _id ${newProductId}`);
    }

    const warnings: string[] = [];

    console.log(`Replacing references to ${oldProductId} with ${newProductId}`);

    console.log("docsWithReferencesToProduct", docsWithReferences);
    const updatedDocsReferencingProduct =
      docsWithReferences?.map(doc =>
        replaceDocumentReferences({
          doc,
          oldDocId: oldProductId,
          newDocId: newProductId,
        })
      ) || [];

    // 2. Look for docs referencing the presets for that product
    const sanityPresetsForOldProduct = await fetchSanityPresetsForProduct(client, oldProductId);
    const sanityPresetsForNewProduct = await fetchSanityPresetsForProduct(client, newProductId);

    console.log("sanityPresetsForOldProduct", sanityPresetsForOldProduct);
    console.log("sanityPresetsForNewProduct", sanityPresetsForNewProduct);

    // find any presets that were cloned from old ones (where cloning = name is the same)
    const newPresetsByName = sanityPresetsForNewProduct.reduce((acc, preset) => {
      acc[preset.name.trim()] = preset;
      return acc;
    }, {} as Record<string, SourcefulPresetQuery>);

    // track the total number of docs referencing presets vs the number of docs updated - will help us identify if there are any issues with a preset not being duplicated for the new product version
    let docsWithReferencesToPresets: GenericSanityDocument[] = [];
    let updatedDocsReferencingPresets: GenericSanityDocument[] = [];

    for (let oldPreset of sanityPresetsForOldProduct) {
      // if we can't find a preset for the new product version with the same name, skip
      const newPresetMatch = newPresetsByName[oldPreset.name.trim()];

      const oldPresetId = oldPreset.id!;
      const docsToUpdate = await fetchSanityDocsReferencingPreset(client, oldPresetId);

      if (!newPresetMatch && docsToUpdate.length === 0) {
        continue;
      } else if (!newPresetMatch && docsToUpdate.length > 0) {
        warnings.push(
          `The preset ${oldPreset.name} with _id ${oldPresetId} has not been duplicated for the new product version but is referenced by ${docsToUpdate.length} documents. Please check this is ok`
        );

        continue;
      }

      console.log(`docs referencing preset:`, {
        oldPresetId,
        docsToUpdate,
        newPresetMatch,
      });

      docsWithReferencesToPresets = [...docsWithReferencesToPresets, ...docsToUpdate];

      const updatedDocs = docsToUpdate.map(doc => {
        return replaceDocumentReferences({
          doc,
          oldDocId: oldPresetId,
          newDocId: newPresetMatch.id!,
        }) as GenericSanityDocument;
      });

      updatedDocsReferencingPresets = [...updatedDocsReferencingPresets, ...updatedDocs];
    }

    // 3. Consolidate patches

    const patchesForPresetReferences = updatedDocsReferencingPresets?.map(docToPatch) || [];
    const patchesForProductReferences = updatedDocsReferencingProduct?.map(docToPatch) || [];

    console.log(
      "patches",
      JSON.stringify({
        patchesForProductReferences,
        patchesForPresetReferences,
      })
    );

    if (!patchesForPresetReferences.length && !patchesForProductReferences.length) {
      console.log(NO_REFERENCE_WARNING);
      onNoReferencesFound && onNoReferencesFound();
      return {
        patchesForProductReferences: [],
        patchesForPresetReferences: [],
        docsWithReferencesToProduct: [],
        docsWithReferencesToPresets: [],
        warnings,
      };
    }

    if (autoCommit) {
      const transaction = client.transaction();
      patchesForPresetReferences.forEach(patch => transaction.patch(patch.id, patch.patch));
      patchesForProductReferences.forEach(patch => transaction.patch(patch.id, patch.patch));
      const result = await transaction.commit();
      console.log("Result", result);
    }

    return {
      patchesForProductReferences,
      patchesForPresetReferences,
      docsWithReferencesToProduct: docsWithReferences,
      docsWithReferencesToPresets,
      warnings,
    };
  } catch (error) {
    onError ? onError(error) : console.error(error.message);
    return {
      patchesForProductReferences: [],
      patchesForPresetReferences: [],
      docsWithReferencesToProduct: [],
      docsWithReferencesToPresets: [],
    };
  }
};
