import { localisedField } from "../../groq-utils/localisedField";
import groq from "groq";

// prettier-ignore
export const internalLinkFields = groq`
      _type,
      "slug": select(
              _type == "productPreset" => ${localisedField("productMain->slug") + ".current"} + "/" + ${
                localisedField("slug") + ".current"
              }, 
              _type == "productPresetPage" || _type == "productPresetMerchantPage" => ${localisedField("productPreset->productMain->slug") + ".current"} + "/" 
                + coalesce(${localisedField("slug")}.current, ${localisedField("productPreset->slug")}.current), // /merchant gets added via the urlHelper
              _type == 'directPurchaseProductPage' => "direct/" + ${localisedField("slug")}.current,
              ${localisedField("slug") + ".current"} // Everything else
            ),
`;

export default internalLinkFields;
