import React, { FC } from "react";

export const RecycledContent100: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.50297 6.57264C9.21754 3.67412 13.761 3.18814 17.1033 5.61088C18.1875 6.39676 19.0189 7.39668 19.5834 8.50785L21.3665 7.6019C20.6613 6.21409 19.6235 4.96753 18.2771 3.99156C14.0508 0.928077 8.25266 1.59137 4.87323 5.3913L3.61849 4.48178C3.45169 4.36087 3.21845 4.48228 3.2218 4.68827L3.28421 8.52109C3.28963 8.85438 3.6135 9.08914 3.93194 8.99061L7.59398 7.85751C7.79078 7.79661 7.8336 7.53717 7.6668 7.41626L6.50297 6.57264Z"
        fill="#222222"
      />
      <path
        d="M21.1579 14.9168L21.442 18.7396C21.4573 18.945 21.2315 19.0797 21.058 18.9686L19.7527 18.1333C16.5989 22.1225 10.8488 23.1202 6.45225 20.3065C5.05161 19.41 3.94339 18.2256 3.15912 16.881L4.88675 15.8733C5.51468 16.95 6.40253 17.9001 7.53036 18.6219C11.0073 20.8472 15.5151 20.0991 18.0573 17.0482L16.8466 16.2734C16.6731 16.1623 16.7008 15.9008 16.8938 15.8286L20.4841 14.4855C20.7963 14.3687 21.1332 14.5843 21.1579 14.9168Z"
        fill="#222222"
      />
      <path
        d="M7.22791 9.902V14.75H8.25191V9.15H6.40391L5.25991 11.678H6.33191L7.08391 9.902H7.22791Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62109 14.318C10.0211 14.6807 10.5518 14.862 11.2131 14.862C11.8798 14.862 12.4104 14.6807 12.8051 14.318C13.2051 13.95 13.4051 13.3953 13.4051 12.654V11.246C13.4051 10.526 13.2051 9.97933 12.8051 9.606C12.4104 9.22733 11.8798 9.038 11.2131 9.038C10.5518 9.038 10.0211 9.22733 9.62109 9.606C9.22109 9.97933 9.02109 10.526 9.02109 11.246V12.654C9.02109 13.3953 9.22109 13.95 9.62109 14.318ZM12.0931 13.622C11.9064 13.8407 11.6131 13.95 11.2131 13.95C10.8238 13.95 10.5304 13.846 10.3331 13.638C10.1411 13.4247 10.0451 13.1073 10.0451 12.686V11.198C10.0451 10.8033 10.1464 10.4967 10.3491 10.278C10.5571 10.0593 10.8451 9.95 11.2131 9.95C11.5704 9.95 11.8531 10.0567 12.0611 10.27C12.2744 10.478 12.3811 10.7873 12.3811 11.198V12.686C12.3811 13.0913 12.2851 13.4033 12.0931 13.622Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6486 14.318C15.0486 14.6807 15.5793 14.862 16.2406 14.862C16.9073 14.862 17.4379 14.6807 17.8326 14.318C18.2326 13.95 18.4326 13.3953 18.4326 12.654V11.246C18.4326 10.526 18.2326 9.97933 17.8326 9.606C17.4379 9.22733 16.9073 9.038 16.2406 9.038C15.5793 9.038 15.0486 9.22733 14.6486 9.606C14.2486 9.97933 14.0486 10.526 14.0486 11.246V12.654C14.0486 13.3953 14.2486 13.95 14.6486 14.318ZM17.1206 13.622C16.9339 13.8407 16.6406 13.95 16.2406 13.95C15.8513 13.95 15.5579 13.846 15.3606 13.638C15.1686 13.4247 15.0726 13.1073 15.0726 12.686V11.198C15.0726 10.8033 15.1739 10.4967 15.3766 10.278C15.5846 10.0593 15.8726 9.95 16.2406 9.95C16.5979 9.95 16.8806 10.0567 17.0886 10.27C17.3019 10.478 17.4086 10.7873 17.4086 11.198V12.686C17.4086 13.0913 17.3126 13.4033 17.1206 13.622Z"
        fill="#222222"
      />
    </svg>
  );
};
