import { LocaleSlug } from "@sourceful/shared-types";

export interface LocaleSlugWithUpdatedAt {
  slug: LocaleSlug;
  updatedAt: string;
}

export const localeSlugsToSitemapEntries = (slugs: LocaleSlugWithUpdatedAt[]) => {
  return slugs.reduce((output, item) => {
    const locales = Object.keys(item.slug);
    const flattenedSlugs: { slug: string; locale: string; updatedAt: string }[] = [];

    locales.forEach(locale => {
      const val = item.slug[locale];
      // skip _type which is a string
      if (val?.current) {
        flattenedSlugs.push({
          slug: val.current,
          locale,
          updatedAt: item.updatedAt,
        });
      }
    });

    output = [...output, ...flattenedSlugs];
    return output;
  }, [] as { slug: string; locale: string; updatedAt: string }[]);
};
