import groq from "groq";
import { localisedField } from "../groq-utils/localisedField";

//TODO: SHOULD BE UNIFIED WITH standardLinkFields

// for some reason using internalLinkFields here causes a runtime error

// prettier-ignore
export const customBlockContentFields = groq`
    ...,
    link {
        ...,
        internalLink-> {
            _type,
            "slug": select(
            _type == "productPreset" => ${localisedField("productMain->slug") + ".current"} + "/" + ${localisedField("slug") + ".current"},
            ${localisedField("slug") + ".current"}
        ) ,
        },
        "externalLink": {
            "link": externalUrl,
            "newWindow": newWindow
        }
    }
`;
