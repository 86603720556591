import groq from "groq";
import { localisedField } from "@sanity-utils/groq-utils/localisedField";
import { informationCardFields } from "../product/informationCardFields";
import { productFeatureSectionFields } from "../product/productFeatureSectionFields";
import { technicalContentItemFields } from "../product/technicalContentItemFields";
import { imageLinkFields } from "../widgets/imageLinkFields";
import { imageWithCaptionFields } from "../widgets/imageWithCaptionFields";
import { gridFields } from "./gridFields";
import { customBlockContentFields } from "../customBlockContentFields";

export const corePageSectionFields = groq`
    _type,
    ...,
    "menuLabel": ${localisedField("menuLabel", "")},
    "title": ${localisedField("title", "")},
    "subtitle": ${localisedField("subtitle", "")}[] {
      ${customBlockContentFields}
    },
    "primaryCTA": primaryCTA[0] {
      "title":  ${localisedField("title")},
      "url":  ${localisedField("url")}
    },
		"secondaryCTA": secondaryCTA[0] {
      "title":  ${localisedField("title")},
      "url":  ${localisedField("url")}
    },
		"linkCTA": linkCTA[0] {
      "title":  ${localisedField("title")},
      "url":  ${localisedField("url")}
    }`;

export const gridSectionFields = groq`
    body {
      ${gridFields}
    }
`;

export const faqSectionFields = groq`
    faqItems[]-> {
      _id,
      "title": ${localisedField("title")},
      tags,
      "content": ${localisedField("content", "")}[] {
        ${customBlockContentFields}
      },
    }
`;

export const imageGallerySectonFields = groq`
  images[] {
    ${imageWithCaptionFields}
  },
  autoplay
`;

export const slideSectionFields = groq`
  _type,
  images[] {
    ${imageWithCaptionFields}
  },
  icon,
  layout
`;

export const heroSectionFields = groq`
  primaryImage {
    ${imageWithCaptionFields}
  },
  backgroundImage {
    ${imageWithCaptionFields}
  },
  layout,
  bgImagePosition,
  bgImageSize,
  fgImagePosition,
  fgImageSize,
  logoWall {
    "title": ${localisedField("title", "Missing Translation")},
    logos[] {
      ${imageWithCaptionFields} 
      ${imageLinkFields}
    }
  },
  breakoutTitle,
  titleAsH1
`;

export const logoWallSectionFields = groq`
  logos[] {
    ${imageWithCaptionFields}
    ${imageLinkFields}
  }
`;

export const pullQuoteSectionFields = groq`
  "quote": ${localisedField("quote")},
  "attributedTo": ${localisedField("attributedTo")},
  "attributedToDescription": ${localisedField("attributedToDescription", "")},
  image {
    ${imageWithCaptionFields}
  }
`;

export const tabsSectionFields = groq`
  tabs[] {
    ...,
    "title": ${localisedField("title")},
    items[] {
      ${corePageSectionFields},
      ${gridFields},
      ${slideSectionFields}
    }
  }
`;

export const productFeaturesSectionFields = groq`
  productFeatureSections[] {
    ${productFeatureSectionFields}
  }
`;

export const technicalContentSectionFields = groq`
  technicalContentItems[] {
    ${technicalContentItemFields}
  }
`;

export const informationCardSectionFields = groq`
  informationCards[]-> {
    ${informationCardFields}
  }
`;

export const pageContentFields = groq`
  ${corePageSectionFields}, // Includes everything for headingSection so no need for separate lookup
  ${gridFields},
  ${faqSectionFields},
  ${imageGallerySectonFields},
  ${slideSectionFields},
  ${heroSectionFields},
  ${logoWallSectionFields},
  ${pullQuoteSectionFields},
  ${tabsSectionFields},
  ${productFeaturesSectionFields},
  ${technicalContentSectionFields},
  ${informationCardSectionFields}
`;
