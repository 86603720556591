import React, { FC } from "react";

export const PlasticFree: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M15.4245 12.4465C15.628 12.7318 15.7411 13.0713 15.7495 13.421L13.9841 11.6556C14.0362 11.5207 14.1056 11.3907 14.1924 11.2691L14.2035 11.2536C14.2337 11.2112 14.25 11.1604 14.25 11.1083V10.2513C14.25 10.188 14.226 10.127 14.1828 10.0807L13.1207 8.94275C12.8182 8.6187 12.65 8.19196 12.65 7.74869V7C12.65 6.86193 12.5381 6.75 12.4 6.75H11.6C11.4619 6.75 11.35 6.86193 11.35 7V7.74869C11.35 8.09827 11.2454 8.43758 11.0528 8.72434L9.85 7.52154V7C9.85 6.0335 10.6335 5.25 11.6 5.25H12.4C13.3665 5.25 14.15 6.0335 14.15 7V7.74869C14.15 7.81202 14.174 7.87298 14.2172 7.91927L15.2793 9.05725C15.5818 9.3813 15.75 9.80804 15.75 10.2513V11.1083C15.75 11.4728 15.6362 11.8282 15.4245 12.1249L15.4133 12.1405C15.3513 12.2274 15.3513 12.3441 15.4133 12.4309L15.4245 12.4465Z"
      fill="#222222"
    />
    <path
      d="M9.79796 13.328C9.82712 13.289 9.85444 13.249 9.87991 13.2083L8.83385 12.1622C8.83385 12.1622 8.6604 12.3444 8.59645 12.43C8.37153 12.7309 8.25 13.0966 8.25 13.4723V14.0992C8.25 14.4749 8.37153 14.8405 8.59645 15.1414C8.6604 15.227 8.6604 15.3444 8.59645 15.43C8.37153 15.7309 8.25 16.0966 8.25 16.4723V17C8.25 17.9665 9.0335 18.75 10 18.75H14C14.3995 18.75 14.7678 18.6161 15.0624 18.3908L13.9216 17.25H10C9.86193 17.25 9.75 17.1381 9.75 17V16.4723C9.75 16.4203 9.76682 16.3696 9.79796 16.328C10.2599 15.7099 10.2599 14.8615 9.79796 14.2434C9.76682 14.2018 9.75 14.1512 9.75 14.0992V13.4723C9.75 13.4203 9.76682 13.3696 9.79796 13.328Z"
      fill="#222222"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM6.81864 5.90442C8.21454 4.71672 10.0236 4 12 4C16.4183 4 20 7.58172 20 12C20 13.9764 19.2833 15.7855 18.0956 17.1814L6.81864 5.90442ZM5.46713 7.38134C4.54307 8.68601 4 10.2796 4 12C4 16.4183 7.58172 20 12 20C13.7204 20 15.314 19.4569 16.6187 18.5329L5.46713 7.38134Z"
      fill="#222222"
    />
  </svg>
);
