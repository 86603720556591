import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { contextualInformationFields } from "./productConfigSectionFields";

export const rfqDropdownOptionFields = groq`
    "label": ${localisedField("label")},
    value
`;
export const rfqQuestionSectionFields = groq`
  "id": _key,
  "title": ${localisedField("title")},
  "description": ${localisedField("description", "")},
  "reference": reference.current,
  contextualInformation[] {
    ${contextualInformationFields}
  },
  questions[] {
      "id": id.current,
      _type,
      "label": ${localisedField("label")},
      "placeholder": ${localisedField("placeholder", "")},
      "helperText": ${localisedField("helperText", "")},
      min,
      helperTextPosition,
      isRequired,
      unit,
      requiredDimensions,
      isOrderQuantity,
      displayType,
      includeOtherOption,
      options[] {
        ${rfqDropdownOptionFields}
      }
  }
`;
