import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { imageFields } from "../widgets/imageFields";
import { learnMoreModalFields } from "./learnMoreModalFields";

export const productFeatureSectionFields = groq`
  "id": _key,
  "title": ${localisedField("title")},
  "description": ${localisedField("description", "")},
  features[]-> {
    "id": _id,
    "title": ${localisedField("title")},
    "description": ${localisedField("description", "")},
    image {
      ${imageFields}
    },
    "imageFallbackText": ${localisedField("imageFallbackText", "")},
    learnMoreModal {
      ${learnMoreModalFields}
    }
  },
  backgroundTheme,
  iconTheme
`;
