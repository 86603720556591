import { SanityClient } from "@sanity/client";
import groq from "groq";
import { SanityPresetFields, SourcefulPresetQuery } from "../fragments";

export interface GenericSanityDocument {
  _id: string;
  _type: string;
  [index: string]: any;
}

export const SANITY_DOCS_REFERENCING_PRODUCT = groq`*[references($productId) && _type != 'productPreset'] {
    ...
   }`;

export const fetchSanityDocsReferencingProduct = async (
  client: SanityClient,
  productId: string
): Promise<GenericSanityDocument[]> => {
  const docs = await client.fetch(SANITY_DOCS_REFERENCING_PRODUCT, { productId });
  return docs;
};

export const SANITY_PRESETS_FOR_PRODUCT = groq`*[_type == 'productPreset' && productMain._ref == $productId] {
  ${SanityPresetFields}
 }`;

export const fetchSanityPresetsForProduct = async (
  client: SanityClient,
  productId: string
): Promise<SourcefulPresetQuery[]> => {
  return client.fetch(SANITY_PRESETS_FOR_PRODUCT, { productId, locale: "en_GB" });
};

export const SANITY_DOCS_REFERENCING_PRESETS = groq`*[references($presetId)] {
  ...
 }`;

export const fetchSanityDocsReferencingPreset = async (
  client: SanityClient,
  presetId: string
): Promise<GenericSanityDocument[]> => {
  return client.fetch(SANITY_DOCS_REFERENCING_PRESETS, {
    presetId,
  });
};
