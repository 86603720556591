// takes the result of a groq query and then filters to a single item
// this function is needed because in preview mode we need to fetch the record with the "drafts." prefix in the id

export const filterDataToSingleItem = <T extends { _id: string }>(
  data: T | T[],
  preview: boolean
): T | null => {
  if (!Array.isArray(data)) {
    return data;
  }

  if (data.length === 1) {
    return data[0];
  }

  if (preview) {
    return data.find(item => item._id.startsWith(`drafts.`)) || data[0];
  }

  return data[0];
};

// Locales in sanity have to to be snake cased to conform to their field name validation
export const toSanityLocale = (locale: string) => {
  return locale.replace("-", "_");
};

// we use kebab case for our sites to conform to the HTML lang attribute spec
export const toBrowserLocale = (locale: string) => {
  return locale.replace("_", "-");
};
