import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { customBlockContentFields } from "../customBlockContentFields";

export const informationCardFields = groq`
  "_key": _key,
  "_type": _type,
  "title": ${localisedField("title")},  
  "iconName": icon,
  "subTitle": ${localisedField("subtitle", "")},
  "content": ${localisedField("content", "")}[] {
    ${customBlockContentFields}
  },
`;
