import { filterDataToSingleItem, localeSlugsToSitemapEntries } from "@src/sanity-utils";
import { DirectPurchaseProduct, LocaleSlug } from "@sourceful/shared-types";
import SanityFetcher from "../sanity-fetcher/SanityFetcher";
import groq from "groq";
import { directPurchaseProductFields } from "../fragments/product/directPurchaseProductFields";

export const DIRECT_PURCHASE_PRODUCT_SLUGS_ALL_LOCALES = groq`
*[_type == "directPurchaseProductPage" && isActive == true] {
   slug,
   "updatedAt": _updatedAt
}`;

export const DIRECT_PURCHASE_PRODUCT_BY_SLUG = groq`
*[_type == "directPurchaseProductPage" && (slug.[$locale].current == $slug)] | order(_updatedAt desc)  {
  ${directPurchaseProductFields}
}
`;

export const CURRENT_DIRECT_PURCHASE_PRODUCT_BY_SLUG = groq`
*[_type == "directPurchaseProductPage" && (slug.[$locale].current == $slug) && isActive == true] | order(_updatedAt desc)  {
  ${directPurchaseProductFields}
}
`;

interface FetchBySlugArgs {
  slug: string;
  activeOnly?: boolean;
}

export class SanityDirectPurchaseProductFetcher extends SanityFetcher {
  fetchDirectPurchaseSlugs = async (): Promise<
    { slug: string; locale: string; updatedAt: string }[]
  > => {
    const result: { slug: LocaleSlug; updatedAt: string }[] = await this.client.fetch(
      DIRECT_PURCHASE_PRODUCT_SLUGS_ALL_LOCALES
    );

    return localeSlugsToSitemapEntries(result);
  };

  fetchBySlug = async ({
    slug,
    activeOnly = true,
  }: FetchBySlugArgs): Promise<DirectPurchaseProduct | null> => {
    let result: DirectPurchaseProduct | DirectPurchaseProduct[] = await this.client.fetch(
      activeOnly ? CURRENT_DIRECT_PURCHASE_PRODUCT_BY_SLUG : DIRECT_PURCHASE_PRODUCT_BY_SLUG,
      {
        locale: this.locale,
        slug,
      }
    );

    const item = filterDataToSingleItem(result, !!this.isPreview);
    if (!item) return null;
    return item;
  };
}
