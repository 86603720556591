import React, { FC } from "react";

export const LowImpactInk: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C12.3199 2 12.6205 2.15308 12.8087 2.41181L14.1689 4.28192L13.0682 6.16881L12 4.70012L9.85557 7.64854L6.82391 12.0667C6.23522 12.9226 6 13.5472 6 14.5714C6 17.197 8.10624 19.4905 11 19.9258V11H13V15.0858L14.7929 13.2929L16.2071 14.7071L13 17.9142V19.9258C15.8938 19.4905 18 17.197 18 14.5714C18 13.8108 17.8275 13.0875 17.5145 12.4296L19.3206 11.5704C19.7568 12.4874 20 13.5035 20 14.5714C20 18.7526 16.3364 22 12 22C7.66359 22 4 18.7526 4 14.5714C4 13.0749 4.39954 12.0624 5.17576 10.9338L8.21424 6.50564C8.21942 6.4981 8.2247 6.49063 8.23007 6.48324L11.1913 2.41181C11.3795 2.15308 11.6801 2 12 2Z"
        fill="#222222"
      />
      <path
        d="M17 10C18.1046 10 19 9.04061 19 7.85714C19 7.43698 18.8871 7.04506 18.6921 6.71429L17 4L15.3079 6.71429C15.1129 7.04506 15 7.43698 15 7.85714C15 9.04061 15.8954 10 17 10Z"
        fill="#222222"
      />
    </svg>
  );
};
