import React, { FC } from "react";

export const RecycledContent30: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.50297 6.57264C9.21754 3.67412 13.761 3.18814 17.1033 5.61088C18.1875 6.39676 19.0189 7.39668 19.5834 8.50785L21.3665 7.6019C20.6613 6.21409 19.6235 4.96753 18.2771 3.99156C14.0508 0.928077 8.25266 1.59137 4.87323 5.3913L3.61849 4.48178C3.45169 4.36087 3.21845 4.48228 3.2218 4.68827L3.28421 8.52109C3.28963 8.85438 3.6135 9.08914 3.93194 8.99061L7.59398 7.85751C7.79078 7.79661 7.8336 7.53717 7.6668 7.41626L6.50297 6.57264Z"
        fill="#222222"
      />
      <path
        d="M21.1579 14.9168L21.442 18.7396C21.4573 18.945 21.2315 19.0797 21.058 18.9686L19.7527 18.1333C16.5989 22.1225 10.8488 23.1202 6.45225 20.3065C5.05161 19.41 3.94339 18.2256 3.15912 16.881L4.88675 15.8733C5.51468 16.95 6.40253 17.9001 7.53036 18.6219C11.0073 20.8472 15.5151 20.0991 18.0573 17.0482L16.8466 16.2734C16.6731 16.1623 16.7008 15.9008 16.8938 15.8286L20.4841 14.4855C20.7963 14.3687 21.1332 14.5843 21.1579 14.9168Z"
        fill="#222222"
      />
      <path
        d="M8.36466 14.638C8.68999 14.7873 9.06333 14.862 9.48466 14.862C9.90599 14.862 10.274 14.79 10.5887 14.646C10.9087 14.4967 11.1567 14.2887 11.3327 14.022C11.5087 13.75 11.5967 13.4327 11.5967 13.07V12.974C11.5967 12.638 11.5193 12.366 11.3647 12.158C11.21 11.9447 11.0127 11.7873 10.7727 11.686C10.538 11.5847 10.2953 11.534 10.0447 11.534H9.74866V11.406L11.4367 10.51V9.15H7.46866V10.062H10.3007V10.19L8.49266 11.15V12.27H9.61266C9.79399 12.27 9.95666 12.2967 10.1007 12.35C10.2447 12.398 10.3593 12.4753 10.4447 12.582C10.53 12.6887 10.5727 12.8273 10.5727 12.998V13.078C10.5727 13.3287 10.4767 13.5367 10.2847 13.702C10.098 13.8673 9.83133 13.95 9.48466 13.95C9.14333 13.95 8.86866 13.862 8.66066 13.686C8.45266 13.5047 8.34866 13.2647 8.34866 12.966V12.854H7.32466V12.998C7.32466 13.382 7.41533 13.7153 7.59666 13.998C7.78333 14.2753 8.03933 14.4887 8.36466 14.638Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7598 14.318C13.1598 14.6807 13.6905 14.862 14.3518 14.862C15.0185 14.862 15.5492 14.6807 15.9438 14.318C16.3438 13.95 16.5438 13.3953 16.5438 12.654V11.246C16.5438 10.526 16.3438 9.97933 15.9438 9.606C15.5492 9.22733 15.0185 9.038 14.3518 9.038C13.6905 9.038 13.1598 9.22733 12.7598 9.606C12.3598 9.97933 12.1598 10.526 12.1598 11.246V12.654C12.1598 13.3953 12.3598 13.95 12.7598 14.318ZM15.2318 13.622C15.0452 13.8407 14.7518 13.95 14.3518 13.95C13.9625 13.95 13.6692 13.846 13.4718 13.638C13.2798 13.4247 13.1838 13.1073 13.1838 12.686V11.198C13.1838 10.8033 13.2852 10.4967 13.4878 10.278C13.6958 10.0593 13.9838 9.95 14.3518 9.95C14.7092 9.95 14.9918 10.0567 15.1998 10.27C15.4132 10.478 15.5198 10.7873 15.5198 11.198V12.686C15.5198 13.0913 15.4238 13.4033 15.2318 13.622Z"
        fill="#222222"
      />
    </svg>
  );
};
