import { localisedField } from "@src/sanity-utils/groq-utils";
import groq from "groq";

export const productAttributeValueGroupingFields = groq`
    _key,
    _type,
    "label": ${localisedField("label")},
    "description": ${localisedField("description", "")},
    values[]
`;
