import {
  SanityFormDropdownField,
  SanityFormGroupField,
  SanityFormTextField,
} from "../fragments/widgets/formFields";

export enum FormNames {
  PPC = "PPC",
  Contact = "Contact",
  RFQ = "RFQ",
  ConfiguratorExitDialog = "ConfiguratorExitDialog",
  LeadGen = "LeadGen",
  LeadGenExitDialog = "LeadGenExitDialog",
}

export interface SanityForm {
  title: string;
  fields: (SanityFormTextField | SanityFormGroupField | SanityFormDropdownField)[] | null;
  fileUploadHeading: string;
  fileUploadText: string;
  consentText: string;
  submitButtonText: string;
}

export function extractRequiredFormFields(formOrFormGroup: any): Array<string> {
  if (!formOrFormGroup.fields) {
    return formOrFormGroup.isRequired ? [formOrFormGroup.id] : [];
  }

  return formOrFormGroup.fields
    .map((field: any) => {
      return extractRequiredFormFields(field);
    })
    .flat(1);
}
