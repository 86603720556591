import React, { FC } from "react";

export const DropOffRecyclable: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.64607 4.97856L9.29435 3.92793C10.393 2.14745 12.9944 2.15474 14.0797 3.94135L19.5993 13.0275L19.824 12.0449L21.7765 12.4885L21.0247 15.7767C20.9017 16.3145 20.365 16.6512 19.8258 16.5287L16.5238 15.7784L16.969 13.831L17.8679 14.0352L12.3641 4.97496C12.0552 4.46651 11.3149 4.46443 11.0022 4.97114L10.3539 6.02178L8.64607 4.97856Z"
        fill="#222222"
      />
      <path
        d="M6.72489 8.61953L5.74475 8.92146L5.15267 7.0087L8.37629 6.01567C8.90349 5.85326 9.46341 6.14979 9.62691 6.67799L10.6282 9.91271L8.71904 10.5008L8.45151 9.63656L3.72709 18.2578C3.43627 18.7885 3.82243 19.4395 4.428 19.4394L5.19549 19.4394L5.2045 21.4409L4.43701 21.441C2.30911 21.4411 0.952251 19.1536 1.97414 17.2888L6.72489 8.61953Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70394 17V16.072H10.4399V12.328H9.70394V11.4H12.0079C12.7599 11.4 13.3306 11.592 13.7199 11.976C14.1146 12.3547 14.3119 12.92 14.3119 13.672V14.728C14.3119 15.48 14.1146 16.048 13.7199 16.432C13.3306 16.8107 12.7599 17 12.0079 17H9.70394ZM11.4959 16.04H12.0239C12.4506 16.04 12.7626 15.928 12.9599 15.704C13.1573 15.48 13.2559 15.1653 13.2559 14.76V13.64C13.2559 13.2293 13.1573 12.9147 12.9599 12.696C12.7626 12.472 12.4506 12.36 12.0239 12.36H11.4959V16.04Z"
        fill="#222222"
      />
      <path
        d="M21.8907 16.5441L22.3198 17.3919C23.2607 19.251 21.9109 21.4589 19.8291 21.466L9.1748 21.5026L9.90021 22.2022L8.51229 23.6454L6.08421 21.304C5.68711 20.921 5.6759 20.2875 6.05916 19.889L8.40631 17.4483L9.84432 18.835L9.20519 19.4996L19.8295 19.4631C20.422 19.461 20.8061 18.8327 20.5384 18.3036L20.1093 17.4558L21.8907 16.5441Z"
        fill="#222222"
      />
    </svg>
  );
};
