export interface Patch {
  id: string;
  patch: {
    set: any;
    ifRevisionID?: string;
  };
}

export const createPatch = <T extends { _id: string }>(updatedDoc: T, _rev: string): Patch => ({
  id: updatedDoc._id,
  patch: {
    set: updatedDoc,
    ifRevisionID: _rev,
  },
});
