import groq from "groq";

export const imageWithVideoFields = groq`
    _type,
    _key,
    crop {
        ...
    },
    asset-> {
        "_type": "reference", 
        "_ref": _id,
        title,
        originalFilename,
        description,
        altText
    },
    metadata {
        youtubeId,
        autoplay
    }
`;
