import groq from "groq";
import {
  Image,
  PageMetaFields,
  ProductAttribute,
  ProductAttributeValue,
  ProductTag,
  SanityPageSectionType,
  TransportPriority,
  StripBannerItem,
} from "@sourceful/shared-types";
import { localisedField } from "../../groq-utils/localisedField";
import { imageFields } from "../widgets/imageFields";
import { productAttributeFields } from "./productAttributeFields";
import { productAttributeOptionsFields } from "./productAttributeOptionFields";
import { customBlockContentFields } from "../customBlockContentFields";
import { productCopyFields, SanityPresetFields, stripBannerItemFields } from "@src/sanity-utils";

export const directPurchaseProductFields = groq`
    _id,
    "slug": coalesce(${localisedField("slug")}.current),
    "name": coalesce(${localisedField("name")}),
    "description": ${localisedField("description", "")}[] {
        ${customBlockContentFields}
    },
    "shortDescription": ${localisedField("shortDescription", "")},
    "price": price,
    "stripBannerItems": stripBannerItems[]-> {
        ${stripBannerItemFields}
    },
    images[] {
        ${imageFields}
    },
    ${productCopyFields}
`;
