import {
  AttributeSelection,
  AttributeSelectionsByAttributeId,
  AttributeValueType,
  ProductAttribute,
  SanityProductPreset,
} from "@sourceful/shared-types";
import groq from "groq";
import { SanityPresetFields, SourcefulPresetQuery } from "../fragments/product/presetFields";
import { localisedField } from "../groq-utils";
import SanityFetcher, { SanityFetcherConstructorArgs } from "../sanity-fetcher/SanityFetcher";
import { removeNulls, removeNullsFromOptionValue } from "./ProductFetcher";

export const ACTIVE_SOURCEFUL_PRESETS_FOR_PRODUCT = groq`
*[_type == "productPreset" && isVisible == true && baseProductId == $baseProductId && versionId == $versionId] {
	${SanityPresetFields}
}
`;

export const VISIBLE_SOURCEFUL_PRESETS = groq`
*[_type == "productPreset" && isVisible == true && productMain->crmOnly == false && productMain->isActiveVersion == true] {
	${SanityPresetFields}
}
`;

export interface SourcefulPresetLocalisedNameQuery {
  name: string;
}
export const SOURCEFUL_PRESET_LOCALISED_NAME = groq`*[_type == "productPreset" && presetId == $presetId][0] {
   "name": ${localisedField("name")}
  }`;

// all presets - for admin view
export const ALL_PRESETS = groq`
*[_type == "productPreset"] {
	${SanityPresetFields}

}
`;

export const PRESETS_FOR_PRODUCTS = groq`
  *[_type == "productPreset" && productMain._ref in $productIds] { 
    ${SanityPresetFields}
  }
`;

// FORMATTERS
export const formatPresetQueryAttributes = (
  rawAttributes: SourcefulPresetQuery["attributes"]
): AttributeSelectionsByAttributeId => {
  const result = rawAttributes.reduce((result, selection) => {
    // remove nulls from the options value objects
    const formattedAttribute: ProductAttribute = {
      ...selection.attribute,
      options: selection.attribute.options.map(removeNullsFromOptionValue),
    };

    const formattedSelection: AttributeSelection = {
      attribute: formattedAttribute,
      value:
        selection.value.length > 1 || selection.attribute.displayType === "checkbox"
          ? selection.value.map(item => removeNulls(item.value) as AttributeValueType)
          : (removeNulls(selection.value[0].value) as AttributeValueType),
    };

    result[selection.attribute.id] = formattedSelection;
    return result;
  }, {} as AttributeSelectionsByAttributeId);

  return result;
};

export const formatPresetGroqQuery = (rawPreset: SourcefulPresetQuery): SanityProductPreset => {
  return {
    id: rawPreset.id,
    name: rawPreset.name,
    isDefault: rawPreset.isDefault,
    thumbnail: rawPreset?.images?.length > 0 ? rawPreset.images[0] : null,
    images: rawPreset?.images || [],
    description: rawPreset.description,
    baseProductId: rawPreset.baseProductId,
    baseProductVersionId: rawPreset.versionId,
    attributes: formatPresetQueryAttributes(rawPreset.attributes),
    isSourcefulPreset: true,
    slug: rawPreset.slug,
    productSlug: rawPreset.productSlug ?? "",
    shortDescription: rawPreset.shortDescription,
    fromPrice: rawPreset.fromPrice,
    defaultTransportPriority: rawPreset.defaultTransportPriority,
  };
};

interface Constructor extends SanityFetcherConstructorArgs {
  formatQuery?: (rawPreset: SourcefulPresetQuery) => SanityProductPreset;
}

export class SanityPresetFetcher extends SanityFetcher {
  formatQuery: (rawPreset: SourcefulPresetQuery) => SanityProductPreset = formatPresetGroqQuery;

  constructor({ client, locale, formatQuery, isPreview }: Constructor) {
    super({ client, locale, isPreview });

    if (formatQuery) {
      this.formatQuery = formatQuery;
    }
  }

  fetchLocalisedNameForPreset = async (presetId: number): Promise<string> => {
    const data: SourcefulPresetLocalisedNameQuery = await this.client.fetch(
      SOURCEFUL_PRESET_LOCALISED_NAME,
      {
        locale: this.locale,
        presetId,
      }
    );

    return data?.name;
  };

  fetchPresetsForProduct = async ({
    baseProductId,
    versionId,
  }: {
    baseProductId: number;
    versionId: number;
  }): Promise<SanityProductPreset[]> => {
    const rawPresets: SourcefulPresetQuery[] = await this.client.fetch(
      ACTIVE_SOURCEFUL_PRESETS_FOR_PRODUCT,
      {
        locale: this.locale,
        baseProductId,
        versionId,
      }
    );

    const formattedPresets: SanityProductPreset[] = rawPresets.map(this.formatQuery);

    return formattedPresets;
  };

  // usePresets hook
  fetchPresets = async () => {
    const rawPresets: SourcefulPresetQuery[] = await this.client.fetch(VISIBLE_SOURCEFUL_PRESETS, {
      locale: this.locale,
    });

    const formattedPresets: SanityProductPreset[] = rawPresets.map(this.formatQuery);

    return formattedPresets;
  };

  fetchPresetsForProducts = async (productIds: string[]) => {
    const rawPresets: SourcefulPresetQuery[] = await this.client.fetch(PRESETS_FOR_PRODUCTS, {
      locale: this.locale,
      productIds,
    });

    const formattedPresets: SanityProductPreset[] = rawPresets.map(this.formatQuery);
    return formattedPresets;
  };

  fetchAllPresetsForAdmin = async (): Promise<SanityProductPreset[]> => {
    const rawPresets: SourcefulPresetQuery[] = await this.client.fetch(ALL_PRESETS, {
      locale: this.locale,
    });

    const formattedPresets: SanityProductPreset[] = rawPresets.map(this.formatQuery);
    return formattedPresets;
  };
}
