export enum BrowserLocale {
  "en-GB" = "en-GB",
}

export enum SanityLocale {
  "en_GB" = "en_GB",
}

export const browserLocales = Object.values(BrowserLocale);
export const sanityLocales = Object.values(SanityLocale);
