import { localisedField } from "@sanity-utils/groq-utils";
import groq from "groq";
import { customBlockContentFields } from "../customBlockContentFields";
import { pageContentFields } from "../layout/sectionFields";
import { pageMetaFields } from "../pageMetaFields";
import { imageFields } from "../widgets/imageFields";
import { stripBannerItemFields } from "../widgets/stripBannerItemsFields";
import { toastFields } from "../widgets/toastFields";
import { productAttributeFields } from "./productAttributeFields";
import { productConfigSectionFields } from "./productConfigSectionFields";
import { productFeatureFields } from "./productFeatureFields";
import { productTagFields } from "./productTagFields";
import { rfqQuestionSectionFields } from "./rfqQuestionSectionFields";
import { imageWithVideoFields } from "../widgets/imageWithVideoFields";

export const coreProductFields = groq`
  "id": _id,
  baseProductId,
  printAreas,
  versionId,
  type,
  productTypeEnum,
  crmOnly,
  maxPrintableSides,
  images[] {
    ${imageWithVideoFields}
  },
  customSizeImage,
  "customSizeDescription": ${localisedField("customSizeDescription", "")}[] {
    ${customBlockContentFields}
  },
  "fromPrice": ${localisedField("fromPrice", "")},
  "pricePerUnit": pricePerUnit,
  // TODO: This might not be working as expected
  "moq": coalesce(moq, *[_type == 'productPreset' && baseProductId == ^.baseProductId && versionId == ^.versionId && isDefault == true][0].attributes[] {
    attribute-> {
      type
    },
    "value": value[0]->value
 } [attribute.type == 'quantity'][0].value[0].value),
  isActiveVersion,
  isCurrentVersion,
  "name": ${localisedField("name")},
  "internalName": ${localisedField("internalName", "")},
  "slug": ${localisedField("slug")}.current,
  "metaDescription": ${localisedField("metaDescription", "")},
  "description": ${localisedField("description", "")}[] {
    ${customBlockContentFields}
  },
  mainCategory->{
    _id,
    "name": ${localisedField("name")},
    "slug": ${localisedField("slug")}.current,
	  image {
      ${imageFields}
    }
  },
  attributes[]-> {
    ${productAttributeFields}
  },
  rfqQuestionSections[] {
    ${rfqQuestionSectionFields}
  },
  rfqQuestionSectionsAbridged[] {
    ${rfqQuestionSectionFields}
  },
  productConfigSections[] {
    ${productConfigSectionFields}
  },
  stripBannerItems[]-> {
    ${stripBannerItemFields}
  },
  studioInitialToasts[] {
    ${toastFields}
  },
`;

export const productCopyFields = groq`
  pageMeta {
    ${pageMetaFields},
  },
  tags[]->{
    ${productTagFields}
  },
  productFeatureSections[] {
    ${productFeatureFields}
  },
  productDetailPageContent[] {
    ${pageContentFields},
  }`;

export const productFields = groq`
  _id,
  ${coreProductFields}
  ${productCopyFields}
`;
