import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";

export interface SanityFormField {
  _key: string;
  _type: string;
  id: string;
  label: string;
  helperText?: string;
  isRequired: boolean;
}

export const baseFormInputFields = groq`
  _key,
  _type,
  "id": id.current,
  "label": ${localisedField("label")},
  "helperText": ${localisedField("helperText")},
  isRequired
`;

export interface SanityFormDropdownField extends SanityFormField {
  _type: "formDropdown";
  options: { label: string; value: string }[] | null;
  includeOtherOption?: boolean;
  displayType: "dropdown" | "radio";
}

export const formDropdownOptionFields = groq`
    "label": ${localisedField("label")},
    value,
    displayType
`;

export const formDropdownFields = groq`
    includeOtherOption,
    options[] {
      ${formDropdownOptionFields}
    }
`;

export interface SanityFormTextField extends SanityFormField {
  _type: "formText";
  dataType: "any" | "email";
  placeholder?: string;
  displayType: "input" | "textarea";
}

export const formTextFields = groq`
    ${baseFormInputFields},
    "label": ${localisedField("label")},
    "placeholder": ${localisedField("placeholder", "")},
    displayType,
    dataType
`;

export interface SanityFormGroupField {
  _key: string;
  _type: "formGroup";
  label: string;
  fields: (SanityFormTextField | SanityFormDropdownField)[] | null;
}

export const formGroupFields = groq`
    _key,
    _type,
    "label": ${localisedField("label")},
    fields[] {
      ${formTextFields},
      ${formDropdownFields}
    }
`;
