import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { imageFields } from "../widgets/imageFields";
import { customBlockContentFields } from "../customBlockContentFields";
import { stripBannerItemFields } from "../widgets/stripBannerItemsFields";
import { productCopyFields } from "./coreProductFields";
import { SanityPresetFields, SourcefulPresetQuery } from "./presetFields";
import { Image, PortableText, StripBannerItem, ProductCopyFields } from "@sourceful/shared-types";

export interface SanityProductPresetPageQuery extends ProductCopyFields {
  _id: string;
  fromPrice?: string;
  name?: string;
  slug: string;
  productSlug: string;
  description?: PortableText;
  stripBannerItems?: StripBannerItem[];
  images?: Image[];
  productPreset: SourcefulPresetQuery;
}

export const SanityPresetPageFields = groq`
    _id,
    "slug": coalesce(${localisedField("slug")}.current, ${localisedField(
  "productPreset->slug"
)}.current),
    "name": coalesce(${localisedField("name")}, ${localisedField("productPreset->name")}),
    "description": ${localisedField("description", "")}[] {
        ${customBlockContentFields}
    },
    "shortDescription": ${localisedField("shortDescription", "")},
    "fromPrice": ${localisedField("fromPrice", "")},
    "stripBannerItems": stripBannerItems[]-> {
        ${stripBannerItemFields}
    },
    images[] {
        ${imageFields}
    },
    productPreset->{
        ${SanityPresetFields}
    },
    ${productCopyFields}
`;
