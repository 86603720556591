import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";

export const toastFields = groq`
    "id": _key,
    "title": ${localisedField("title")},
    "body": ${localisedField("body")},
    icon,
    iconColor,
    duration,
    showCloseIcon,
    blockUiInteraction,
`;
