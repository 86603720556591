import cloneDeep from "lodash/cloneDeep";

const REFERENCE_TYPE = "reference";
export const DEPTH_EXCEEDED_ERROR_MESSAGE = "Recursive Depth limit exceeded";
interface ReplaceProductRefsArgs {
  doc: { [index: string]: any };
  oldDocId: string;
  newDocId: string;
  depth?: number;
  depthLimit?: number;
}

export const replaceDocumentReferences = ({
  doc,
  oldDocId,
  newDocId,
  depth = 1,
  depthLimit = 20,
}: ReplaceProductRefsArgs) => {
  // guard against getting stuck in highly nested object - objects should not exceed this level of nesting
  if (depth > depthLimit) {
    throw new Error(DEPTH_EXCEEDED_ERROR_MESSAGE);
  }

  const updatedDoc = cloneDeep(doc);

  // base case
  if (doc?._type === REFERENCE_TYPE && doc?._ref === oldDocId) {
    return { ...doc, _ref: newDocId };
  }

  for (let field in doc) {
    const value = doc[field];

    if (Array.isArray(value)) {
      const updated = value.map(item =>
        replaceDocumentReferences({
          doc: item,
          oldDocId: oldDocId,
          newDocId,
          depth: depth + 1,
          depthLimit,
        })
      );

      updatedDoc[field] = updated;
      continue;
    }

    if (typeof value === "object") {
      // objects of other types may have nested references
      const updated = replaceDocumentReferences({
        doc: value,
        oldDocId: oldDocId,
        newDocId,
        depth: depth + 1,
        depthLimit,
      });
      updatedDoc[field] = updated;
    }
  }

  return updatedDoc;
};
