import groq from "groq";
import { localisedField, localisedArrayField } from "../../groq-utils/localisedField";

export const learnMoreModalFields = groq`
  ...,
  "triggerLabel": ${localisedField("triggerLabel")},
  modal->{
    ...,
    "title": ${localisedField("title")},
    contentBlocks[] {
      ...,
      "title": ${localisedField("title", "")},
      "subtitle": ${localisedField("subtitle", "")},
      "content": ${localisedArrayField("content")},
    },
  }
`;
