import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
export const imageWithCaptionFields = groq`
    _type,
    _key,
    crop {
      ...
    },
    hotspot {
      ...
    },
    asset-> {
      "_type": "reference",
      "_ref": _id,
      title,
      originalFilename,
      description,
      altText
    },
    metadata {
      "caption": ${localisedField("caption")}
    },
`;
