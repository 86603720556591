import groq from "groq";
import SanityFetcher, { SanityFetcherConstructorArgs } from "../sanity-fetcher/SanityFetcher";
import { LocaleSlug, SanityProductPresetPage } from "@sourceful/shared-types";
import { filterDataToSingleItem } from "../sanity-fetcher/helpers";
import { formatPresetGroqQuery } from "./PresetFetcher";
import {
  SanityPresetPageFields,
  SanityProductPresetPageQuery,
} from "../fragments/product/presetPageFields";

// QUERIES

export const PRESET_BY_SLUG = groq`*[_type == "productPresetPage" && (slug.[$locale].current == $slug || productPreset->slug.[$locale].current == $slug)] | order(_updatedAt desc) {    
  ${SanityPresetPageFields}

}`;

export const CURRENT_PRESET_BY_SLUG = groq`*[_type == "productPresetPage" && productPreset->isVisible == true && productPreset->productMain->isActiveVersion == true && productPreset->productMain->crmOnly == false && (slug.[$locale].current == $slug || productPreset->slug.[$locale].current == $slug)] | order(_updatedAt desc) {    
  ${SanityPresetPageFields}
}`;

export const PRESET_MERCHANT_BY_SLUG = groq`*[_type == "productPresetMerchantPage" && (slug.[$locale].current == $slug || productPreset->slug.[$locale].current == $slug)] | order(_updatedAt desc) {    
  ${SanityPresetPageFields}
}`;

export const CURRENT_PRESET_MERCHANT_BY_SLUG = groq`*[_type == "productPresetMerchantPage" && productPreset->isVisible == true && productPreset->productMain->isActiveVersion == true && productPreset->productMain->crmOnly == false && (slug.[$locale].current == $slug || productPreset->slug.[$locale].current == $slug)] | order(_updatedAt desc) {    
  ${SanityPresetPageFields}
}`;

export const PRESET_SLUGS_ALL_LOCALES = groq`*[_type in ["productPresetPage", "productPresetMerchantPage"] && productPreset->isVisible == true && productPreset->isDefault == false && productPreset->productMain->isActiveVersion == true && productPreset->productMain->crmOnly == false] {
  "slug": coalesce(slug, productPreset->slug),
  "productSlug": productPreset->productMain->slug,
  "updatedAt": _updatedAt,
  "type": _type
}`;

export const VISIBLE_PRESET_PAGES = groq`*[_type == "productPresetPage" && productPreset->isVisible == true && productPreset->productMain->isActiveVersion == true && productPreset->productMain->crmOnly == false] {    
  ${SanityPresetPageFields}
}`;

export const formatPresetPageGroqQuery = (
  rawPage: SanityProductPresetPageQuery
): SanityProductPresetPage => {
  const { productPreset, ...rest } = rawPage;
  return {
    ...rest,
    productPreset: formatPresetGroqQuery(productPreset),
  };
};

interface FetchBySlugArgs {
  slug: string;
  activeOnly?: boolean;
  merchant?: boolean;
}

interface Constructor extends SanityFetcherConstructorArgs {
  formatQuery?: (rawPreset: SanityProductPresetPageQuery) => SanityProductPresetPage;
}

export class SanityPresetPageFetcher extends SanityFetcher {
  formatQuery: (rawPreset: SanityProductPresetPageQuery) => SanityProductPresetPage =
    formatPresetPageGroqQuery;

  constructor({ client, locale, formatQuery, isPreview }: Constructor) {
    super({ client, locale, isPreview });

    if (formatQuery) {
      this.formatQuery = formatQuery;
    }
  }

  getFetchBySlugQuery = (activeOnly: boolean, merchant: boolean) => {
    if (activeOnly) {
      if (merchant) {
        return CURRENT_PRESET_MERCHANT_BY_SLUG;
      } else {
        return CURRENT_PRESET_BY_SLUG;
      }
    }

    if (merchant) {
      return PRESET_MERCHANT_BY_SLUG;
    } else {
      return PRESET_BY_SLUG;
    }
  };

  fetchBySlug = async ({
    slug,
    activeOnly = true,
    merchant = false,
  }: FetchBySlugArgs): Promise<SanityProductPresetPage | null> => {
    let result: SanityProductPresetPageQuery | SanityProductPresetPageQuery[] =
      await this.client.fetch(this.getFetchBySlugQuery(activeOnly, merchant), {
        locale: this.locale,
        slug,
      });

    const item = filterDataToSingleItem(result, !!this.isPreview);

    if (!item) return null;

    return this.formatQuery(item);
  };

  // For static generation
  fetchVisiblePages = async () => {
    const rawPresets: SanityProductPresetPageQuery[] = await this.client.fetch(
      VISIBLE_PRESET_PAGES,
      {
        locale: this.locale,
      }
    );

    const formattedPresets: SanityProductPresetPage[] = rawPresets.map(this.formatQuery);

    return formattedPresets;
  };

  // Sitemap
  fetchProductPresetSlugs = async (): Promise<
    { slug: string; locale: string; updatedAt: string; type: string }[]
  > => {
    const result: { slug: LocaleSlug; productSlug: LocaleSlug; updatedAt: string; type: string }[] =
      await this.client.fetch(PRESET_SLUGS_ALL_LOCALES);

    const flattened = result?.reduce((output, item) => {
      const locales = Object.keys(item.slug);

      const flattenedSlugs: { slug: string; locale: string; updatedAt: string; type: string }[] =
        [];

      locales.forEach(locale => {
        const val = item.slug[locale];
        const productSlug = item.productSlug[locale];
        // skip _type which is a string
        if (val?.current) {
          flattenedSlugs.push({
            slug: productSlug.current + "/" + val.current,
            locale,
            updatedAt: item.updatedAt,
            type: item.type,
          });
        }
      });

      output = [...output, ...flattenedSlugs];
      return output;
    }, [] as { slug: string; locale: string; updatedAt: string; type: string }[]);

    return flattened;
  };
}
