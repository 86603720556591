import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { ctaFields } from "./ctaFields";
import { imageFields } from "./imageFields";

export const baseCardFields = groq`
    "title": ${localisedField("title")},
    "subtitle": ${localisedField("subtitle", "")},
    tags,
    image {
        ${imageFields}
    },
    "ctaText": ${localisedField("ctaText")},
    "customCTA": customCTA[0] {
        ${ctaFields}
    }
`;
