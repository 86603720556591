import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { imageWithCaptionFields } from "../widgets/imageWithCaptionFields";
import { informationCardFields } from "./informationCardFields";
import { technicalContentSpecItemFields } from "./technicalContentSpecItemFields";

export const technicalContentItemFields = groq`
  "_key": _key,
  "_type": _type,
  "title": ${localisedField("title")},
  "isTitleHidden": isTitleHidden,
  image {
    ${imageWithCaptionFields}
  },
  mobileImage {
    ${imageWithCaptionFields}
  },
  "isMobileImageModal": isMobileImageModal,
  technicalContentSpecItems[] {
    ${technicalContentSpecItemFields}
  },
  informationCards[]-> {
    ${informationCardFields}
  }
`;
