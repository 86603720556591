import {
  AttributeValueType,
  CoreProductFields,
  LocaleSlug,
  ProductAttributeValue,
  ProductMain,
  ProductMainRaw,
} from "@sourceful/shared-types";
import groq from "groq";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { coreProductFields, productFields } from "../fragments/product/coreProductFields";
import { localeSlugsToSitemapEntries } from "../groq-utils/localeSlugsToSitemapEntries";
import { filterDataToSingleItem } from "../sanity-fetcher";
import SanityFetcher from "../sanity-fetcher/SanityFetcher";

export const ALL_PRODUCTS = groq`*[_type == "productMain"] {
  ${productFields}
}`;

export const ALL_PRODUCTS_CORE_FIELDS = groq`*[_type == "productMain"] {
  ${coreProductFields}
}`;

export const ALL_CURRENT_PRODUCTS_CORE_FIELDS = groq`*[_type == "productMain" && isActiveVersion == true] {
  ${coreProductFields}
}`;

export const ALL_CURRENT_PRODUCTS = groq`*[_type == "productMain" && isActiveVersion == true] {
  ${productFields}
}`;

export const PRODUCT_SLUGS_ALL_LOCALES = groq`*[_type == "productMain" && isActiveVersion == true && crmOnly == false] {
   slug,
   "updatedAt": _updatedAt
}`;

export const PRODUCT_BY_SLUG = groq`*[_type == "productMain" && (slug.[$locale].current == $slug)] | order(_updatedAt desc) {    
  ${productFields}
}`;

export const CURRENT_PRODUCT_BY_SLUG = groq`*[_type == "productMain" && crmOnly == false && isActiveVersion == true && (slug.[$locale].current == $slug)] | order(_updatedAt desc) {
  ${productFields}
}`;

export const PRODUCT_BY_ID_INCL_CRM_ONLY = groq`*[_type == "productMain" && baseProductId == $baseProductId && versionId == $versionId] | order(_updatedAt desc) {    
  ${productFields}
}`;

export const PRODUCT_BY_ID = groq`*[_type == "productMain" && crmOnly == false && baseProductId == $baseProductId && versionId == $versionId] | order(_updatedAt desc) {    
  ${productFields}
}`;

interface FetchBySlugArgs {
  slug: string;
  activeOnly?: boolean;
}

interface FetchByIdArgs {
  baseProductId: number;
  versionId: number;
  includeCrmOnly?: boolean;
}

interface FechAllArgs {
  activeOnly?: boolean;
  coreFieldsOnly?: boolean;
  includeCrmOnlyProducts?: boolean;
}

export class SanityProductFetcher extends SanityFetcher {
  fetchAll = async ({
    activeOnly = true,
    coreFieldsOnly = false,
    includeCrmOnlyProducts = false,
  }: FechAllArgs = {}): Promise<ProductMain[]> => {
    let query = ALL_PRODUCTS;

    if (activeOnly && coreFieldsOnly) {
      query = ALL_CURRENT_PRODUCTS_CORE_FIELDS;
    } else if (activeOnly && !coreFieldsOnly) {
      query = ALL_CURRENT_PRODUCTS;
    } else if (coreFieldsOnly) {
      query = ALL_PRODUCTS_CORE_FIELDS;
    }

    const result: ProductMainRaw[] = await this.client.fetch(query, {
      locale: this.locale,
    });

    // preview mode will show crmOnly products regardless of includeCrmOnlyProducts as we need to be able to view crmOnly products in preview while prepping for live priced launch
    if (!activeOnly) {
      return result.map(formatRawProduct);
    }

    if (!includeCrmOnlyProducts) {
      return result.map(formatRawProduct).filter(product => !product.crmOnly);
    }

    return result.map(formatRawProduct);
  };

  fetchProductSlugs = async (): Promise<{ slug: string; locale: string; updatedAt: string }[]> => {
    const result: { slug: LocaleSlug; updatedAt: string }[] = await this.client.fetch(
      PRODUCT_SLUGS_ALL_LOCALES
    );

    return localeSlugsToSitemapEntries(result);
  };

  fetchBySlug = async ({
    slug,
    activeOnly = true,
  }: FetchBySlugArgs): Promise<ProductMain | null> => {
    let result: ProductMainRaw | ProductMainRaw[] = await this.client.fetch(
      activeOnly ? CURRENT_PRODUCT_BY_SLUG : PRODUCT_BY_SLUG,
      {
        locale: this.locale,
        slug,
      }
    );

    const item = filterDataToSingleItem(result, !!this.isPreview);
    if (!item) return null;
    return formatRawProduct(item);
  };

  fetchById = async ({
    baseProductId,
    versionId,
    includeCrmOnly = false,
  }: FetchByIdArgs): Promise<ProductMain | null> => {
    let result: ProductMainRaw | ProductMainRaw[] = await this.client.fetch(
      includeCrmOnly ? PRODUCT_BY_ID_INCL_CRM_ONLY : PRODUCT_BY_ID,
      {
        locale: this.locale,
        baseProductId,
        versionId,
      }
    );

    const item = filterDataToSingleItem(result, !!this.isPreview);
    if (!item) return null;
    return formatRawProduct(item);
  };
}

export const removeNulls = <T extends object>(object: T) => {
  return omitBy(object, isNil);
};

export const removeNullsFromOptionValue = (option: ProductAttributeValue) => {
  const formattedValue = removeNulls(option.value) as AttributeValueType;
  return {
    ...option,
    value: formattedValue,
  };
};

export const formatRawProduct = (product: ProductMainRaw): ProductMain => {
  return {
    ...product,
    // normalise attribute data
    attributes: product.attributes?.reduce(
      (acc, current) => {
        acc.ids.push(current.id);
        acc.entities[current.id] = {
          ...current,
          options: current.options.map(removeNullsFromOptionValue) || [],
        };
        return acc;
      },
      {
        ids: [],
        entities: {},
      } as CoreProductFields["attributes"]
    ) || { ids: [], entities: {} },
  };
};
