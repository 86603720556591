import groq from "groq";
import { SanityFetcher } from "../sanity-fetcher";
import { Image } from "@sourceful/shared-types";
import { localisedField } from "../groq-utils";

export const STUDIO_LOADING_SCREEN_QUERY = groq`*[_type == "studioLoading"][0] {
  _id, 
  loadingContent[]{
      _key,
      "text": ${localisedField("text", "")},
      image
    }
  }`;

export interface LoadingImageAndText {
  _key: string;
  image: Image;
  text: string;
}

export interface StudioLoadingScreen {
  _id: string;
  loadingContent: LoadingImageAndText[];
}

export class SanityStudioLoadingScreenFetcher extends SanityFetcher {
  fetch = async (): Promise<StudioLoadingScreen> => {
    const result: StudioLoadingScreen = await this.client.fetch(STUDIO_LOADING_SCREEN_QUERY, {
      locale: this.locale,
    });

    return result;
  };
}
