import React, { FC } from "react";
import { DropOffRecyclable } from "@sanity-utils/components/sustainability-icon/DropOffRecyclable";
import { KerbsideRecyclable } from "@sanity-utils/components/sustainability-icon/KerbsideRecyclable";
import { LowImpactInk } from "@sanity-utils/components/sustainability-icon/LowImpactInk";
import { RecycledContent30 } from "@sanity-utils/components/sustainability-icon/RecycledContent30";
import { RecycledContent98 } from "@sanity-utils/components/sustainability-icon/RecycledContent98";
import { RecycledContent100 } from "@sanity-utils/components/sustainability-icon/RecycledContent100";
import { PlasticFree } from "@sanity-utils/components/sustainability-icon/PlasticFree";
import { RecycledContent } from "@sanity-utils/components/sustainability-icon/RecycledContent";

const icons = {
  DropOffRecyclable,
  KerbsideRecyclable,
  LowImpactInk,
  RecycledContent,
  RecycledContent30,
  RecycledContent98,
  RecycledContent100,
  PlasticFree,
};

export type SustainabilityIconName = keyof typeof icons;
export const SustainabilityIconNames = Object.keys(icons) as SustainabilityIconName[];

interface SustainabilityIconProps {
  name: SustainabilityIconName;
}

export const SustainabilityIcon: FC<SustainabilityIconProps> = ({ name }) => {
  const Icon = icons[name];
  if (!Icon) {
    return null;
  }
  return <Icon />;
};
