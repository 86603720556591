import React, { FC } from "react";

export const RecycledContent98: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.50297 6.57264C9.21754 3.67412 13.761 3.18814 17.1033 5.61088C18.1875 6.39676 19.0189 7.39668 19.5834 8.50785L21.3665 7.6019C20.6613 6.21409 19.6235 4.96753 18.2771 3.99156C14.0508 0.928077 8.25266 1.59137 4.87323 5.3913L3.61849 4.48178C3.45169 4.36087 3.21845 4.48228 3.2218 4.68827L3.28421 8.52109C3.28963 8.85438 3.6135 9.08914 3.93194 8.99061L7.59398 7.85751C7.79078 7.79661 7.8336 7.53717 7.6668 7.41626L6.50297 6.57264Z"
        fill="#222222"
      />
      <path
        d="M21.1579 14.9168L21.442 18.7396C21.4573 18.945 21.2315 19.0797 21.058 18.9686L19.7527 18.1333C16.5989 22.1225 10.8488 23.1202 6.45225 20.3065C5.05161 19.41 3.94339 18.2256 3.15912 16.881L4.88675 15.8733C5.51468 16.95 6.40253 17.9001 7.53036 18.6219C11.0073 20.8472 15.5151 20.0991 18.0573 17.0482L16.8466 16.2734C16.6731 16.1623 16.7008 15.9008 16.8938 15.8286L20.4841 14.4855C20.7963 14.3687 21.1332 14.5843 21.1579 14.9168Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40091 14.654C8.71024 14.7927 9.07291 14.862 9.48891 14.862C9.90491 14.862 10.2729 14.7873 10.5929 14.638C10.9182 14.4887 11.1716 14.2647 11.3529 13.966C11.5396 13.662 11.6329 13.2833 11.6329 12.83V10.918C11.6329 10.55 11.5476 10.2247 11.3769 9.942C11.2062 9.65933 10.9636 9.438 10.6489 9.278C10.3342 9.118 9.96357 9.038 9.53691 9.038C9.11024 9.038 8.73691 9.118 8.41691 9.278C8.09691 9.43266 7.84891 9.64866 7.67291 9.926C7.49691 10.2033 7.40891 10.5287 7.40891 10.902V10.998C7.40891 11.3713 7.49424 11.6913 7.66491 11.958C7.83557 12.2193 8.05957 12.4193 8.33691 12.558C8.61424 12.6967 8.91291 12.766 9.23291 12.766C9.59557 12.766 9.86757 12.718 10.0489 12.622C10.2356 12.526 10.3742 12.4247 10.4649 12.318H10.6089V12.894C10.6089 13.2193 10.5129 13.478 10.3209 13.67C10.1289 13.8567 9.85157 13.95 9.48891 13.95C9.13157 13.95 8.87291 13.8673 8.71291 13.702C8.55824 13.5367 8.48091 13.342 8.48091 13.118H7.44091C7.44091 13.4807 7.52357 13.7927 7.68891 14.054C7.85957 14.3153 8.09691 14.5153 8.40091 14.654ZM10.0969 11.83C9.93691 11.91 9.74491 11.95 9.52091 11.95C9.30224 11.95 9.11024 11.91 8.94491 11.83C8.77957 11.75 8.65157 11.638 8.56091 11.494C8.47557 11.35 8.43291 11.1793 8.43291 10.982V10.918C8.43291 10.6193 8.52891 10.3847 8.72091 10.214C8.91824 10.038 9.18491 9.95 9.52091 9.95C9.85691 9.95 10.1209 10.038 10.3129 10.214C10.5102 10.3847 10.6089 10.6193 10.6089 10.918V10.982C10.6089 11.1793 10.5636 11.35 10.4729 11.494C10.3876 11.638 10.2622 11.75 10.0969 11.83Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3782 14.67C13.7089 14.798 14.0876 14.862 14.5142 14.862C14.9409 14.862 15.3169 14.798 15.6422 14.67C15.9729 14.542 16.2289 14.358 16.4102 14.118C16.5969 13.8727 16.6902 13.5847 16.6902 13.254V13.158C16.6902 12.902 16.6449 12.6913 16.5542 12.526C16.4636 12.3553 16.3516 12.222 16.2182 12.126C16.0849 12.03 15.9542 11.9633 15.8262 11.926V11.782C15.9542 11.7393 16.0769 11.67 16.1942 11.574C16.3169 11.478 16.4156 11.35 16.4902 11.19C16.5702 11.03 16.6102 10.838 16.6102 10.614V10.518C16.6102 10.2247 16.5222 9.96867 16.3462 9.75C16.1756 9.526 15.9329 9.35266 15.6182 9.23C15.3036 9.102 14.9356 9.038 14.5142 9.038C14.0929 9.038 13.7249 9.102 13.4102 9.23C13.0956 9.35266 12.8502 9.526 12.6742 9.75C12.5036 9.96867 12.4182 10.2247 12.4182 10.518V10.614C12.4182 10.838 12.4556 11.03 12.5302 11.19C12.6102 11.35 12.7116 11.478 12.8342 11.574C12.9569 11.67 13.0796 11.7393 13.2022 11.782V11.926C13.0796 11.9633 12.9489 12.03 12.8102 12.126C12.6769 12.222 12.5649 12.3553 12.4742 12.526C12.3836 12.6913 12.3382 12.902 12.3382 13.158V13.254C12.3382 13.5847 12.4289 13.8727 12.6102 14.118C12.7969 14.358 13.0529 14.542 13.3782 14.67ZM15.3622 13.742C15.1649 13.8807 14.8822 13.95 14.5142 13.95C14.1462 13.95 13.8609 13.8807 13.6582 13.742C13.4609 13.598 13.3622 13.4087 13.3622 13.174V13.11C13.3622 12.8753 13.4609 12.6887 13.6582 12.55C13.8556 12.406 14.1409 12.334 14.5142 12.334C14.8929 12.334 15.1782 12.406 15.3702 12.55C15.5676 12.6887 15.6662 12.8753 15.6662 13.11V13.174C15.6662 13.4087 15.5649 13.598 15.3622 13.742ZM15.3062 11.238C15.1196 11.3607 14.8556 11.422 14.5142 11.422C14.1729 11.422 13.9089 11.3607 13.7222 11.238C13.5356 11.1153 13.4422 10.942 13.4422 10.718V10.654C13.4422 10.43 13.5382 10.2567 13.7302 10.134C13.9222 10.0113 14.1836 9.95 14.5142 9.95C14.8449 9.95 15.1062 10.0113 15.2982 10.134C15.4902 10.2567 15.5862 10.43 15.5862 10.654V10.718C15.5862 10.942 15.4929 11.1153 15.3062 11.238Z"
        fill="#222222"
      />
    </svg>
  );
};
