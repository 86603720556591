import React, { FC } from "react";

export const KerbsideRecyclable: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.64607 4.97866L9.29435 3.92802C10.393 2.14754 12.9944 2.15483 14.0797 3.94144L19.5993 13.0276L19.824 12.045L21.7765 12.4886L21.0247 15.7768C20.9017 16.3146 20.365 16.6512 19.8258 16.5287L16.5238 15.7785L16.969 13.8311L17.8679 14.0353L12.3641 4.97505C12.0552 4.4666 11.3149 4.46453 11.0022 4.97123L10.3539 6.02187L8.64607 4.97866Z"
        fill="#222222"
      />
      <path
        d="M6.72489 8.61943L5.74475 8.92136L5.15267 7.0086L8.37629 6.01557C8.90349 5.85317 9.46341 6.1497 9.62691 6.67789L10.6282 9.91262L8.71904 10.5007L8.45151 9.63646L3.72709 18.2577C3.43627 18.7884 3.82243 19.4394 4.428 19.4394L5.1955 19.4393L5.2045 21.4408L4.43701 21.4409C2.30911 21.441 0.952252 19.1535 1.97414 17.2887L6.72489 8.61943Z"
        fill="#222222"
      />
      <path
        d="M21.8907 16.5441L22.3198 17.3919C23.2607 19.251 21.9109 21.4589 19.8291 21.466L9.1748 21.5026L9.90021 22.2022L8.51229 23.6454L6.08421 21.304C5.68711 20.921 5.6759 20.2875 6.05916 19.889L8.40631 17.4483L9.84432 18.835L9.20519 19.4996L19.8295 19.4631C20.422 19.4611 20.8061 18.8327 20.5384 18.3036L20.1093 17.4558L21.8907 16.5441Z"
        fill="#222222"
      />
    </svg>
  );
};
