import groq from "groq";
import standardLinkFields from "../layout/standardLinkFields";
import { localisedField } from "../../groq-utils/localisedField";
export const stripBannerItemFields = groq`
    iconName,
    "text": ${localisedField("text")},
    link {
      ${standardLinkFields}
    }
`;
