import { SanityClient } from "@sanity/client";
import { toSanityLocale } from "./helpers";

export interface SanityFetcherConstructorArgs {
  client: SanityClient;
  locale: string;
  isPreview?: boolean;
}

export class SanityFetcher {
  readonly client: SanityClient;
  readonly locale: string;
  readonly isPreview?: boolean;

  constructor({ client, locale, isPreview }: SanityFetcherConstructorArgs) {
    this.client = client;
    this.locale = toSanityLocale(locale);
    this.isPreview = isPreview;
  }
}

export default SanityFetcher;
