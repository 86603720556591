import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { customBlockContentFields } from "../customBlockContentFields";
import { SanityPresetFields } from "../product/presetFields";
import { bannerFields } from "../widgets/bannerFields";
import { baseCardFields } from "../widgets/baseCardFields";
import { ctaFields } from "../widgets/ctaFields";
import { imageFields } from "../widgets/imageFields";
import { imageWithCaptionFields } from "../widgets/imageWithCaptionFields";

export const gridFields = groq`
      _type,
      gridGap,
      constrainContent,
      rows[] {
        _key,
        _type,
        textAlign,
        layout,
        items[] {
            ...,
            "ctaText": ${localisedField("ctaText", "")},
            "title": ${localisedField("title", "")},
            width,
            textAlign,
            layout,
            banner-> {
                ${bannerFields}
            },
            card-> {
                _id,
                "title": ${localisedField("title")},
                "content": ${localisedField("content", "")}[] {
                    ${customBlockContentFields}
                },
                coverImage {
                    ${imageWithCaptionFields}
                },
                primaryCTA {
                    ${ctaFields}
                },
                linkCTAs[] {
                    ${ctaFields}
                }
            },
            presetCard-> {
                _id,
                preset-> {
                    ${SanityPresetFields}
                },
                useDefaultPresetImage,
                usePresetNameAsTitle,
                ${baseCardFields}
            },
            productCard-> {
                _id,
                product-> {
                    "name": ${localisedField("name")},
                    "slug": ${localisedField("slug")}.current,
                    "description": ${localisedField("description", "")},
                    images,

                },
                useDefaultProductImage,
                useProductNameAsTitle,
                ${baseCardFields}
            }

        }
      }
`;
