import { SanityClient } from "@sanity/client";
import groq from "groq";

export type FetchSanityProductById = (
  client: SanityClient,
  _id: string
) => Promise<{
  _id: string;
} | null>;

export const SANITY_PRODUCT_BY_ID = groq`*[_type == "productMain" && _id == $_id && !(_id in path('drafts.**'))] { 
    _id, 
  }[0]`;

export const fetchSanityProductById: FetchSanityProductById = async (client, _id) => {
  const product: { _id: string } = await client.fetch(SANITY_PRODUCT_BY_ID, { _id });

  return product || null;
};
