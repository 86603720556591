import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { imageFields } from "../widgets/imageFields";

export const productAttributeOptionsFields = groq`
  "id": _id,
  "label": ${localisedField("label")},
  "description": ${localisedField("description", "")},
  "shortDescription": ${localisedField("shortDescription", "")},
  image {
    ${imageFields}
  },
  "isCustom": false,
  "tags": tag[] {
    text,
    iconName,
    "backgroundColour": backgroundColor
  },
  "value": value[0] {
    id,
    rfq,
    value,
    values,
    unit,
    width,
    height,
    length
  }
`;
