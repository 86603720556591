import groq from "groq";
import { localisedField } from "../../groq-utils/localisedField";
import { ctaFields } from "./ctaFields";
import { imageFields } from "./imageFields";

export const bannerFields = groq`
    "title": ${localisedField("title")},
    "description": ${localisedField("description")},
    "primaryCTA": primaryCTA[0] {
        ${ctaFields}
    },
    "secondaryCTA": secondaryCTA[0] {
        ${ctaFields}
    },
    "linkCTA": linkCTA[0] {
        ${ctaFields}
    },
    bgImage {
        ${imageFields}
    },
    fgImage {
        ${imageFields} 
    }
`;
