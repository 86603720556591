import groq from "groq";

export const imageFields = groq`
    _type,
    _key,
    crop {
      ...
    },
    hotspot {
      ...
    },
    asset-> {
      "_type": "reference", 
      "_ref": _id,
      title,
      originalFilename,
      description,
      altText
    }
`;
